.talentList2 {
  width: 100%;
  padding-top: 3.2rem;
}

.talentList2 .help-button {
  width: 2.666667rem;
  height: 1.226667rem;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.talentList2 .help-button>div {
  height: 0.853333rem;
  line-height: 0.373333rem;
  width: 2.133333rem;
  background: linear-gradient(267deg, #1990ff 0%, #2c25ff 100%);
  border-radius: 0.426667rem;
  margin-top: 0.266667rem;
  padding: 0.053333rem 0 0 0.266667rem;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  right: 0.533333rem;
}

.talentList2 .help-button>div>span {
  display: inline-block;
  width: 0.8rem;
  font-size: 0.32rem;
}

.talentList2 .help-button>div i {
  margin-right: 0.053333rem;
  position: relative;
  top: -0.16rem;
}

.talentList2 .help-button>img {
  float: left;
  margin-top: -0.266667rem;
  position: relative;
  z-index: 10;
  left: -0.533333rem;
}

.talentList2 .float-wrapper {
  padding: 0.4rem 0;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.talentList2 .float-wrapper .float-item .float-item-text {
  text-align: center;
}

.talentList2 .head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
  border-bottom: 0.03rem solid rgba(204,204,204,0.4);
}

.talentList2 .head .update-time-wrapper {
  padding: 0.4rem 8vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talentList2 .head .update-time-wrapper .interview-time-text {
  font-size: 0.4rem;
  color: #333333;
}

.talentList2 .head .update-time-wrapper .update-btn {
  cursor: pointer;
  background: #fff;
  border: 0.01rem solid #1890ff;
  color: #1890ff;
  padding: 0.1rem 0.5rem;
}

.talentList2 .head .tab-wrapper {
  width: 100%;
  display: flex;
  font-size: 0.37rem;
  padding: 0.35rem 8vw;
  border-top: 0.01rem solid rgba(204,204,204,0.4);
  border-bottom: 0.01rem solid rgba(204,204,204,0.4);
  justify-content: space-between;
}

.talentList2 .head .tab-wrapper .tab {
  text-align: center;
  width: 1.6rem;
  font-size: 0.4rem;
}

.talentList2 .head .tab-wrapper .active {
  color: #1f93fd;
}

.talentList2 .list-wrapper {
  width: 100%;
}

.talentList2 .list-wrapper .right-slot {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.talentList2 .list-wrapper .right-slot .row1 {
  font-size: 0.32rem;
  text-align: left;
  padding-right: 0.5rem;
}

.talentList2 .list-wrapper .right-slot .row1 .text {
  color: #333333;
  font-size: 0.35rem;
}

.talentList2 .list-wrapper .right-slot .row1 .time {
  margin-top: 0.2rem;
  color: #b3b3b3;
  font-size: 0.35rem;
}

.talentList2 .list-wrapper .right-slot .row1 .recommendName {
  margin-top: 0.2rem;
  color: #b3b3b3;
  font-size: 0.35rem;
}

.talentList2 .nocandidate {
  width: 65%;
  margin: 0.533333rem auto;
}

.talentList2 .nocandidate img {
  width: 100%;
}

.talentList2 .nocandidate .text {
  margin-top: 0.533333rem;
  color: #666666;
  text-align: center;
  font-size: 0.533333rem;
}

.talentList2 .van-popup--right {
  width: 80%;
  height: 100%;
}

.video-popup .el-dialog__body {
  padding: 0;
}

.video-popup .el-dialog__header {
  display: none;
}